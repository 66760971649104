import * as yup from 'yup';

export const jobClosureReplyValidationSchema = yup.object({
  jobClosureReplyStatusId: yup
    .number()
    .min(1, 'Status is required.')
    .required('Status is required.'),
  notes: yup.string().max(2_000, 'Notes cannot be more than 2,000 characters.').nullable()
});

export class JobClosureReplyDto {
  id?: number = undefined;
  jobClosureId?: number = undefined;
  jobClosureReplyStatusId?: number = undefined;
  notes?: string = undefined;
}
