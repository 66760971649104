import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { useMediaQuery, useScrollTrigger } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAuth } from 'context';
import React, { useEffect, useState } from 'react';
import { getMicrosoftGraphBearerToken } from 'utils';
import Header from './Header';
import MenuDrawer from './MenuDrawer';
import theme from './theme';

const Layout = styled('div')({
  display: 'flex',
  minHeight: '100%'
});

const Body = styled('main')({
  flexGrow: 1,
  overflow: 'hidden',
  padding: theme.spacing(2),
  marginTop: '64px',
  marginBottom: '64px'
});

const AppLayout = ({ children }: React.PropsWithChildren<{}>) => {
  const { getUserRoles } = useAuth();
  const { instance, accounts, inProgress } = useMsal();

  // If the client's display is a mobile device, the MenuDrawer
  // must be toggled via the Header menu button. Otherwise, it
  // is persistent/static.
  const isMobile: boolean = useMediaQuery('(max-width: 768px)'); // 960px?
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(!isMobile);

  // `trigger` is used for collapsing the header when the user scrolls down.
  const trigger = useScrollTrigger({ target: window ?? undefined });

  useEffect(() => {
    if (isMobile) {
      setIsDrawerOpen(false);
    }
  }, [isMobile]);

  const onToggleMenu = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const onCloseDrawer = () => setIsDrawerOpen(false);

  useEffect(() => {
    if (accounts.length > 0) {
      const bearerToken = getMicrosoftGraphBearerToken();

      if (bearerToken) {
        getUserRoles(bearerToken);
      }
    }
  }, [accounts, getUserRoles]);

  if (accounts.length === 0 && inProgress !== 'login') {
    instance.loginRedirect({
      scopes: ['User.Read', 'User.Read.All', 'Group.Read.All', 'GroupMember.Read.All']
    });

    return <></>;
  }

  return (
    <AuthenticatedTemplate>
      <Layout>
        <Header onToggleMenu={onToggleMenu} trigger={trigger} />

        <MenuDrawer
          isMobile={isMobile}
          isDrawerOpen={isDrawerOpen}
          onCloseDrawer={onCloseDrawer}
          trigger={trigger}
        />

        <Body>{children}</Body>
      </Layout>
    </AuthenticatedTemplate>
  );
};

export default AppLayout;
