import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { JobClosureDto, JobClosureReplyDto, jobClosureReplyValidationSchema } from 'dtos';
import { Formik, setNestedObjectValues } from 'formik';
import ResponsiveDialog from './ResponsiveDialog';
import ResponsiveDialogActions from './ResponsiveDialogActions';
import ResponsiveDialogContent from './ResponsiveDialogContent';
import ResponsiveDialogTitle from './ResponsiveDialogTitle';
import { formatDateTime } from 'utils';

interface ReplyToJobClosureDialogProps {
  isReplyingToJobClosure?: boolean;
  jobClosure: JobClosureDto;
  onClose: () => void;
  onSave: (values: JobClosureReplyDto) => void;
  open: boolean;
}

export default function ReplyToJobClosureDialog({
  isReplyingToJobClosure = false,
  jobClosure,
  onClose,
  onSave,
  open
}: ReplyToJobClosureDialogProps) {
  // This is to prevent state residue between submissions.
  if (!open) {
    return <></>;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...new JobClosureReplyDto(),
        jobClosureId: jobClosure.id
      }}
      validateOnChange
      validateOnBlur
      validationSchema={jobClosureReplyValidationSchema}
      onSubmit={values => {
        onSave(values);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        resetForm,
        setFieldValue,
        setTouched,
        touched,
        values
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <ResponsiveDialog open={open}>
              <ResponsiveDialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ResponsiveDialogTitle>
                      Reply to Job #{jobClosure.jobNumber} Closure
                    </ResponsiveDialogTitle>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isReplyingToJobClosure}
                      error={Boolean(
                        touched.jobClosureReplyStatusId && errors.jobClosureReplyStatusId
                      )}
                      fullWidth
                      helperText={
                        touched.jobClosureReplyStatusId && errors.jobClosureReplyStatusId
                      }
                      InputLabelProps={{ shrink: true }}
                      label='Status'
                      name='jobClosureReplyStatusId'
                      onBlur={handleBlur}
                      onChange={e => {
                        handleChange(e);
                        if (+e.target.value === 2) {
                          const rightNow = new Date().toISOString();
                          let note = 'Approved ' + formatDateTime(rightNow);

                          if (values.notes) {
                            note = values.notes + '\n' + note;
                          }

                          setFieldValue('notes', note);
                        }
                      }}
                      select
                      SelectProps={{ displayEmpty: true }}
                      size='small'
                      value={values.jobClosureReplyStatusId || undefined}
                    >
                      <MenuItem value={undefined}>Awaiting Approval</MenuItem>
                      <MenuItem value={2}>Approved</MenuItem>
                      <MenuItem value={3}>Rejected</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isReplyingToJobClosure}
                      error={Boolean(touched.notes && errors.notes)}
                      fullWidth
                      helperText={touched.notes && errors.notes}
                      label='Notes'
                      maxRows={4}
                      minRows={4}
                      multiline
                      name='notes'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      size='small'
                      value={values.notes || ''}
                    />
                  </Grid>
                </Grid>
              </ResponsiveDialogContent>

              <ResponsiveDialogActions>
                <Button
                  disabled={isReplyingToJobClosure}
                  color='error'
                  onClick={() => {
                    onClose();
                    resetForm();
                  }}
                >
                  CANCEL
                </Button>

                <Button
                  disabled={isReplyingToJobClosure}
                  color='primary'
                  variant='contained'
                  onClick={_ => {
                    // setTouched is called here so that if a user opens the dialog and immediately tries to submit,
                    // the yup error messages/helpertext are displayed.
                    setTouched({ jobClosureReplyStatusId: true, notes: true });
                    handleSubmit();
                  }}
                >
                  {isReplyingToJobClosure ? 'LOADING...' : 'SUBMIT'}
                </Button>
              </ResponsiveDialogActions>
            </ResponsiveDialog>
          </form>
        );
      }}
    </Formik>
  );
}
