import { Button, Grid, TextField } from '@mui/material';
import * as api from 'api';
import { FormQuestion, Page } from 'components';
import { JobClosureDto, jobClosureValidationSchema } from 'dtos';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

export default function Form() {
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  return (
    <Page title='Project Manager' isLoading={isSubmitting}>
      <Formik
        enableReinitialize
        initialValues={new JobClosureDto()}
        validateOnChange
        validateOnBlur
        validationSchema={jobClosureValidationSchema}
        onSubmit={(values, formikHelpers) => {
          setIsSubmitting(true);
          api
            .createJobClosure(values)
            .then(({ value }) => {
              enqueueSnackbar(`Job #${value.jobNumber} Closure Successfully Submitted!`, {
                variant: 'success'
              });
              formikHelpers.resetForm({ values: new JobClosureDto() });
            })
            .finally(() => {
              setIsSubmitting(false);
            });
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={12} sm={7} container justifyContent='flex-end'>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      error={Boolean(touched.jobNumber && errors.jobNumber)}
                      fullWidth
                      helperText={touched.jobNumber && errors.jobNumber}
                      inputProps={{ maxLength: 6 }}
                      label='Job #'
                      name='jobNumber'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      size='small'
                      value={values.jobNumber || ''}
                      sx={{ maxWidth: '150px' }}
                    />
                  </Grid>
                </Grid>

                <FormQuestion
                  title='Committed Costs Remaining?'
                  subtitle='Are there any outstanding committed costs that you are expecting to
                      be invoiced for? If not, have A/P clear the commitments.'
                  name='committedCostsRemaining'
                />

                <FormQuestion
                  title='Open Receivables?'
                  subtitle='If yes, the job cannot be closed.'
                  name='openReceivables'
                />

                <FormQuestion
                  title='Open Accounts Payable Invoices?'
                  subtitle='Get with A/P Manager. Do you have items to approve in workflow? Are you expecting a credit?'
                  name='openAccountsPayableInvoices'
                />

                <FormQuestion
                  title='Are There Pendings?'
                  subtitle='If yes, are you expecting a change order? If not expecting a change
                  order, get with Contracts to remove the pending.'
                  name='areTherePendings'
                />

                <FormQuestion
                  title='Is This Job Fully Billed & Received?'
                  subtitle='If you are fully billed, but the contract is a higher amount than
                  your total billings, get with contracts to put on a negative change
                  order.'
                  name='isThisJobFullyBilledAndReceived'
                />

                <Grid item xs={12} sm={7} container spacing={1}>
                  <Grid item xs={12} sm={8} />

                  <Grid item xs={12} sm={4}>
                    <Button
                      color='inherit'
                      fullWidth
                      sx={{ fontWeight: 'bold', maxWidth: '150px' }}
                      type='submit'
                      variant='contained'
                    >
                      SUBMIT
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </Page>
  );
}
