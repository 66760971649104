import {
  AccountInfo,
  Configuration as MSALConfiguration,
  PublicClientApplication
} from '@azure/msal-browser';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

const msalConfiguration: MSALConfiguration = {
  auth: {
    authority: 'https://login.microsoftonline.com/1af5dee0-5c70-4293-99b2-b079042f0c57',
    clientId: 'ff90a431-4fd1-49dc-8dff-3bf5b1c89d3e',
    navigateToLoginRequestUrl: true,
    // @ts-ignore
    groupMembershipClaims: 'SecurityGroup' // or 'All'
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true
  }
};

const publicClientApplication = new PublicClientApplication(msalConfiguration);

class ApiError {
  statusCode?: number = undefined;
  message?: string = undefined;

  constructor(error: any) {
    if (error && error.response && error.response.data) {
      if (error.response.data.statusCode) {
        this.statusCode = error.response.data.statusCode;
      }

      if (error.response.data.message) {
        this.message = error.response.data.message;
      }
    }
  }
}

const baseURL = '/api/';
const scopes = [
  // 'https://graph.microsoft.com/.default',
  'Group.Read.All',
  'GroupMember.Read.All',
  'User.Read',
  'User.Read.All',
  'profile',
  'openid',
  'email'
];

export const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use(
  async config => {
    let accountInfo: AccountInfo | null = null;
    let tokenResponse = await publicClientApplication.handleRedirectPromise();

    if (tokenResponse) {
      accountInfo = tokenResponse.account;
    } else {
      accountInfo = publicClientApplication.getAllAccounts()[0];
    }

    if (accountInfo && tokenResponse) {
      // Intentionally empty
    } else if (accountInfo) {
      try {
        tokenResponse = await publicClientApplication.acquireTokenSilent({
          account: accountInfo ?? undefined,
          scopes
        });
      } catch {
        await publicClientApplication.acquireTokenRedirect({ scopes });
      }
    } else {
      await publicClientApplication.loginRedirect({ scopes });
    }

    config.headers.Authorization = `Bearer ${tokenResponse?.idToken /* accessToken */}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => response,
  error => {
    const apiError = new ApiError(error);

    if (apiError.message) {
      enqueueSnackbar(apiError.message, { variant: 'error' });
    }

    throw apiError;
  }
);
