const UserRoleName = 'Field Report User';
const AdminRoleName = 'Field Report Admin';

export interface MicrosoftGraphToken {
  cachedAt: string;
  clientId: string;
  credentialType: string;
  environment: string;
  expiresOn: string;
  extendedExpiresOn: string;
  homeAccountId: string;
  realm: string;
  secret: string;
  target: string;
  tokenType: string;
}

export interface MicrosoftGraphMemberOfResponse {
  '@odata.type': string;
  classification: unknown;
  createdDateTime: string;
  creationOptions: unknown;
  deletedDateTime: unknown;
  description: string;
  displayName: string;
  expirationDateTime: unknown;
  groupTypes: unknown[];
  id: string;
  isAssignableToRole: unknown;
  mail: unknown;
  mailEnabled: boolean;
  mailNickname: string;
  membershipRule: unknown;
  membershipRuleProcessingState: unknown;
  onPremisesDomainName: unknown;
  onPremisesLastSyncDateTime: unknown;
  onPremisesNetBiosName: unknown;
  onPremisesProvisioningErrors: unknown[];
  onPremisesSamAccountName: unknown;
  onPremisesSecurityIdentifier: unknown;
  onPremisesSyncEnabled: unknown;
  preferredDataLocation: unknown;
  preferredLanguage: unknown;
  proxyAddresses: unknown[];
  renewedDateTime: string;
  resourceBehaviorOptions: unknown[];
  resourceProvisioningOptions: unknown[];
  securityEnabled: boolean;
  securityIdentifier: string;
  theme: unknown;
  visibility: unknown;
}

interface MicrosoftGraphTokenSessionStorageKeyNames {
  // Access Token is the JWT for Microsoft Graph
  accessToken: string[];
  idToken: string[];
  refreshToken: string[];
}

export function getMicrosoftGraphTokenSessionStorageKeyNames(): MicrosoftGraphTokenSessionStorageKeyNames {
  return JSON.parse(
    sessionStorage.getItem('msal.token.keys.ff90a431-4fd1-49dc-8dff-3bf5b1c89d3e')!
  );
}

export function getMicrosoftGraphBearerToken(): string {
  const microsoftGraphTokenKeyNames = getMicrosoftGraphTokenSessionStorageKeyNames();

  if (
    microsoftGraphTokenKeyNames &&
    microsoftGraphTokenKeyNames.accessToken &&
    microsoftGraphTokenKeyNames.accessToken.length > 0
  ) {
    const microsoftGraphToken: MicrosoftGraphToken = JSON.parse(
      sessionStorage.getItem(microsoftGraphTokenKeyNames.accessToken[0])!
    );

    if (microsoftGraphToken) {
      return microsoftGraphToken.secret;
    }
  }

  return '';
}

// The API uses the idToken, whereas Microsoft Graph Client uses the accessToken.
export function getMicrosoftGraphBearerTokenForApi(): string {
  const microsoftGraphTokenKeyNames = getMicrosoftGraphTokenSessionStorageKeyNames();

  if (
    microsoftGraphTokenKeyNames &&
    microsoftGraphTokenKeyNames.accessToken &&
    microsoftGraphTokenKeyNames.accessToken.length > 0
  ) {
    const microsoftGraphToken: MicrosoftGraphToken = JSON.parse(
      sessionStorage.getItem(microsoftGraphTokenKeyNames.accessToken[0])!
    );

    if (microsoftGraphToken) {
      return microsoftGraphToken.secret;
    }
  }

  return '';
}
