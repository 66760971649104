import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { JobClosureDto } from 'dtos';
import { getIn, useFormikContext } from 'formik';

interface FormQuestionProps {
  name: string;
  subtitle?: string;
  title: string;
}

export default function FormQuestion({ name, subtitle, title }: FormQuestionProps) {
  const { errors, handleBlur, handleChange, touched, values } =
    useFormikContext<JobClosureDto>();

  return (
    <Grid
      item
      xs={12}
      sm={7}
      container
      spacing={2}
      alignItems='center'
      // justifyContent='space-around'
    >
      <Grid item xs={12} sm={8}>
        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>

        <Typography variant='body2'>{subtitle}</Typography>
      </Grid>

      <Grid item xs={12} sm='auto'>
        <FormControl
          error={Boolean(getIn(touched, name) && getIn(errors, name))}
          fullWidth
        >
          <RadioGroup
            name={name}
            onBlur={handleBlur}
            onChange={handleChange}
            row
            value={getIn(values, name)}
          >
            <FormControlLabel
              value='true'
              control={<Radio checked={getIn(values, name) === 'true'} />}
              label='Yes'
            />
            <FormControlLabel
              value='false'
              control={<Radio checked={getIn(values, name) === 'false'} />}
              label='No'
            />
          </RadioGroup>

          <FormHelperText sx={{ marginLeft: '4px' }}>
            {getIn(touched, name) && getIn(errors, name)}
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
