import { CircularProgress, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { MCE_RED } from '../app/theme';

const LoadingContainer = styled('div')(() => ({
  position: 'fixed',
  zIndex: 1,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.6)'
}));

export interface PageProps {
  title?: string;
  isLoading?: boolean;
}

const Page = ({
  title,
  children,
  isLoading = false
}: React.PropsWithChildren<PageProps>) => {
  return (
    <Grid container spacing={2} style={{ padding: 20, overflow: 'hidden' }}>
      {title && (
        <Grid
          item
          xs={12}
          container
          justifyContent='space-between'
          style={{ backgroundColor: MCE_RED, padding: '8px' }}
        >
          <Grid item>
            <Typography variant='h5' style={{ textAlign: 'center', color: '#fff' }}>
              {title}
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12}>
        {children}
      </Grid>

      {isLoading && (
        <LoadingContainer>
          <CircularProgress color='inherit' size={70} />
        </LoadingContainer>
      )}
    </Grid>
  );
};

export default Page;
