import { useMsal } from '@azure/msal-react';
import { Button, ButtonBase, CircularProgress, Menu, MenuItem } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import React from 'react';
import { viewAnalytics } from 'api';

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
  const { instance } = useMsal();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isViewingAnalytics, setIsViewingAnalytics] = React.useState(false);

  const onMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const onClose = () => {
    if (window.confirm('Are you sure you would like to exit?')) {
      sessionStorage.clear();
      window.history.go(-(window.history.length - 1));
    }
  };

  const onLogout = logoutType => {
    if (logoutType === 'popup') {
      instance.logoutPopup({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/'
      });
    } else if (logoutType === 'redirect') {
      instance.logoutRedirect({
        postLogoutRedirectUri: '/'
      });
    }
  };

  return (
    <div>
      <Button disabled={isViewingAnalytics} onClick={onClose} style={{ color: '#fff' }}>
        EXIT
      </Button>

      <ButtonBase
        variant='text'
        onClick={onMenuOpen}
        style={{
          color: '#fff',
          minHeight: 36.49
        }}
      >
        <KeyboardArrowDown />
      </ButtonBase>

      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MenuItem
          disabled={isViewingAnalytics}
          onClick={() => {
            if (window.confirm('Are you sure you would like to logout?')) {
              onLogout('redirect');
            }
          }}
        >
          LOGOUT
        </MenuItem>

        <MenuItem
          disabled={isViewingAnalytics}
          onClick={() => {
            setIsViewingAnalytics(true);
            viewAnalytics().finally(() => {
              setIsViewingAnalytics(false);
            });
          }}
        >
          VIEW ANALYTICS{' '}
          {isViewingAnalytics && <CircularProgress size={20} sx={{ ml: 2 }} />}
        </MenuItem>
      </Menu>
    </div>
  );
};
