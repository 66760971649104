import { Reply } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  MenuItem,
  TablePagination,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import * as api from 'api';
import { Page, ReplyToJobClosureDialog } from 'components';
import { useAuth } from 'context';
import { JobClosureDto, JobClosuresParameters } from 'dtos';
import { useQuery } from 'hooks';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { formatDateTime, getJobClosureReplyStatusById, useDebounce } from 'utils';

interface SubmittedCardProps {
  jobClosure: JobClosureDto;
  onReply: (jobClosure: JobClosureDto) => void;
}

function SubmittedCard({ jobClosure, onReply }: SubmittedCardProps) {
  const { IS_ADMIN } = useAuth();

  return (
    <Grid item xs={12}>
      <Card variant='outlined'>
        <CardContent>
          <Grid container alignItems='center' spacing={1}>
            <Grid item xs={12} sm={3}>
              <Typography variant='body1' fontWeight={600} align='right'>
                Job #:
              </Typography>
            </Grid>

            <Grid item xs={12} sm={9}>
              <Typography variant='body1' align='left'>
                {jobClosure.jobNumber}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography variant='body1' fontWeight={600} align='right'>
                Submitted:
              </Typography>
            </Grid>

            <Grid item xs={12} sm={9}>
              <Typography variant='body1' align='left'>
                {formatDateTime(jobClosure.createdDateTimeUtc!)}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography variant='body1' fontWeight={600} align='right'>
                Submitted By:
              </Typography>
            </Grid>

            <Grid item xs={12} sm={9}>
              <Typography variant='body1' align='left'>
                {jobClosure.submittedBy}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography variant='body1' fontWeight={600} align='right'>
                Status:
              </Typography>
            </Grid>

            <Grid item xs={12} sm={9}>
              <Typography variant='body1' align='left'>
                {getJobClosureReplyStatusById(
                  jobClosure.latestJobClosureReplyStatusId ?? 0,
                  jobClosure.latestJobClosureReplyCreatedDateTimeUtc
                )}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>

        <CardActions sx={{ mb: 1, mr: 1 }}>
          <Grid container justifyContent='flex-end'>
            {IS_ADMIN && (
              <Grid item>
                <Tooltip title={`Reply to Job #${jobClosure.jobNumber} Closure`}>
                  <IconButton
                    onClick={() => {
                      onReply(jobClosure);
                    }}
                  >
                    <Reply />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  );
}

export default function Submitted() {
  const query = useQuery();
  const [_searchParams, setSearchParams] = useSearchParams();

  // jobNumber is used when
  const [id, setId] = useState<string | null>(query.get('id'));

  const [isGettingJobClosures, setIsGettingJobClosures] = useState<boolean>(true);
  const [isGettingJobClosureById, setIsGettingJobClosureById] = useState<boolean>(false);
  const [isReplyingToJobClosure, setIsReplyingToJobClosure] = useState<boolean>(false);
  const [isExportingJobClosures, setIsExportingJobClosures] = useState<boolean>(false);

  const [totalCount, setTotalCount] = useState<number>(0);
  const [jobClosures, setJobClosures] = useState<JobClosureDto[]>([]);
  const [parameters, setParameters] = useState<JobClosuresParameters>({
    page: 0,
    pageSize: 10
  });

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [jobClosure, setJobClosure] = useState<JobClosureDto>(new JobClosureDto());

  const [searchBy, setSearchBy] = useState<
    'jobNumber' | 'submitted' | 'submittedBy' | 'jobClosureReplyStatusId'
  >('jobNumber');

  useEffect(() => {
    onFilter(parameters);
  }, [
    parameters.page,
    parameters.pageSize,
    parameters.jobNumber,
    parameters.submittedFrom,
    parameters.submittedTo,
    parameters.submittedBy,
    parameters.jobClosureReplyStatusId
  ]);

  useEffect(() => {
    if (id) {
      setIsGettingJobClosureById(true);
      api
        .getJobClosureById(id)
        .then(response => {
          setJobClosure(response.value);
          setDialogOpen(true);
        })
        .finally(() => {
          setIsGettingJobClosureById(false);
        });
    }
  }, [id]);

  const onFilter = useDebounce((parameters: JobClosuresParameters) => {
    setIsGettingJobClosures(true);
    api
      .getJobClosures(parameters)
      .then(response => {
        setTotalCount(response.totalCount!);
        setJobClosures(response.value);
      })
      .finally(() => {
        setIsGettingJobClosures(false);
      });
  }, 250);

  return (
    <Page
      title='Submitted Job Closures'
      isLoading={
        isGettingJobClosures ||
        isGettingJobClosureById ||
        isReplyingToJobClosure ||
        isExportingJobClosures
      }
    >
      <ReplyToJobClosureDialog
        isReplyingToJobClosure={isReplyingToJobClosure}
        onClose={() => {
          setJobClosure(new JobClosureDto());
          setDialogOpen(false);
          setSearchParams(undefined);
        }}
        onSave={values => {
          setIsReplyingToJobClosure(true);
          api
            .createJobClosureReply(values)
            .then(_ => {
              enqueueSnackbar(
                `Replied to Job #${jobClosure.jobNumber} Closure Successfully!`,
                {
                  variant: 'success'
                }
              );
              onFilter(parameters);
              setDialogOpen(false);
              setSearchParams(undefined);
            })
            .finally(() => {
              setIsReplyingToJobClosure(false);
            });
        }}
        jobClosure={jobClosure}
        open={dialogOpen}
      />

      <Grid container xs={12} sm={6} spacing={2} alignItems='center'>
        {/* HEADER */}
        <Grid item xs={12} container justifyContent='space-between' alignItems='center'>
          <Grid item xs={12} sm={10} container spacing={1} alignItems='center'>
            <Grid item xs={12} sm={4}>
              <TextField
                select
                label='Search By'
                fullWidth
                size='small'
                onChange={e => {
                  setParameters({
                    ...parameters,
                    jobNumber: undefined,
                    submittedFrom: undefined,
                    submittedTo: undefined,
                    submittedBy: undefined,
                    jobClosureReplyStatusId: undefined
                  });

                  setSearchBy(
                    e.target.value as
                      | 'jobNumber'
                      | 'submitted'
                      | 'submittedBy'
                      | 'jobClosureReplyStatusId'
                  );
                }}
                value={searchBy}
              >
                <MenuItem value='jobNumber'>Job #</MenuItem>

                <MenuItem value='submitted'>Submitted</MenuItem>

                <MenuItem value='submittedBy'>Submitted By</MenuItem>

                <MenuItem value='jobClosureReplyStatusId'>Status</MenuItem>
              </TextField>
            </Grid>

            {searchBy === 'jobNumber' && (
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 6 }}
                  label='Search Job #'
                  onChange={e => {
                    setParameters({ ...parameters, jobNumber: e.target.value });
                  }}
                  size='small'
                  value={parameters.jobNumber}
                />
              </Grid>
            )}

            {searchBy === 'submitted' && (
              <>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label='From'
                    onChange={e => {
                      setParameters({ ...parameters, submittedFrom: e.target.value });
                    }}
                    size='small'
                    type='date'
                    value={parameters.submittedFrom}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label='To'
                    onChange={e => {
                      setParameters({ ...parameters, submittedTo: e.target.value });
                    }}
                    size='small'
                    type='date'
                    value={parameters.submittedTo}
                  />
                </Grid>
              </>
            )}

            {searchBy === 'submittedBy' && (
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label='Search Submitted By'
                  onChange={e => {
                    setParameters({ ...parameters, submittedBy: e.target.value });
                  }}
                  size='small'
                  value={parameters.submittedBy}
                />
              </Grid>
            )}

            {searchBy === 'jobClosureReplyStatusId' && (
              <Grid item xs={12} sm={4}>
                <TextField
                  select
                  label='Search Status'
                  fullWidth
                  size='small'
                  onChange={e => {
                    setParameters({
                      ...parameters,
                      jobClosureReplyStatusId: e.target.value
                        ? +e.target.value
                        : undefined
                    });
                  }}
                  value={parameters.jobClosureReplyStatusId}
                >
                  <MenuItem value={1}>Awaiting Approval</MenuItem>

                  <MenuItem value={2}>Approved</MenuItem>

                  <MenuItem value={3}>Rejected</MenuItem>
                </TextField>
              </Grid>
            )}
          </Grid>

          <Grid item>
            <Button
              variant='contained'
              color='inherit'
              onClick={() => {
                setIsExportingJobClosures(true);
                api.exportJobClosures(parameters).finally(() => {
                  setIsExportingJobClosures(false);
                });
              }}
            >
              EXPORT
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12} container spacing={1} alignItems='center'>
          {jobClosures.map(jobClosure => (
            <SubmittedCard
              jobClosure={jobClosure}
              onReply={jobClosure => {
                setJobClosure(jobClosure);
                setDialogOpen(true);
              }}
              key={jobClosure.id}
            />
          ))}
        </Grid>

        <Grid item xs={12} alignItems='center' justifyContent='flex-end'>
          <Grid item>
            <TablePagination
              component='div'
              count={totalCount}
              labelRowsPerPage='Cards per page:'
              onPageChange={(_, page: number) => {
                setParameters({ ...parameters, page });
              }}
              onRowsPerPageChange={e => {
                setParameters({ ...parameters, pageSize: +e.target.value });
              }}
              page={parameters.page}
              rowsPerPage={parameters.pageSize}
            />
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
}
