import axios from 'axios';
import { useState, createContext, useContext } from 'react';
import {
  Configuration as MSALConfiguration,
  PublicClientApplication
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

const USER_GROUP_NAME = 'Field Report User';
const ADMIN_GROUP_NAME = 'Field Report Admin';

export interface MicrosoftGraphMeResponse {
  '@odata.context': string;
  businessPhones: unknown[];
  displayName: string;
  givenName: string;
  id: string;
  jobTitle: unknown;
  mail: unknown;
  mobilePhone: unknown;
  officeLocation: unknown;
  preferredLanguage: unknown;
  surname: string;
  userPrincipalName: string;
}

export interface MicrosoftGraphMemberOfResponse {
  '@odata.type': string;
  classification: unknown;
  createdDateTime: string;
  creationOptions: unknown;
  deletedDateTime: unknown;
  description: string;
  displayName: string;
  expirationDateTime: unknown;
  groupTypes: unknown[];
  id: string;
  isAssignableToRole: unknown;
  mail: unknown;
  mailEnabled: boolean;
  mailNickname: string;
  membershipRule: unknown;
  membershipRuleProcessingState: unknown;
  onPremisesDomainName: unknown;
  onPremisesLastSyncDateTime: unknown;
  onPremisesNetBiosName: unknown;
  onPremisesProvisioningErrors: unknown[];
  onPremisesSamAccountName: unknown;
  onPremisesSecurityIdentifier: unknown;
  onPremisesSyncEnabled: unknown;
  preferredDataLocation: unknown;
  preferredLanguage: unknown;
  proxyAddresses: unknown[];
  renewedDateTime: string;
  resourceBehaviorOptions: unknown[];
  resourceProvisioningOptions: unknown[];
  securityEnabled: boolean;
  securityIdentifier: string;
  theme: unknown;
  visibility: unknown;
}

const msalConfiguration: MSALConfiguration = {
  auth: {
    clientId: 'ff90a431-4fd1-49dc-8dff-3bf5b1c89d3e',
    authority: 'https://login.microsoftonline.com/1af5dee0-5c70-4293-99b2-b079042f0c57',
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true
  }
};

const publicClientApplication = new PublicClientApplication(msalConfiguration);

export const AuthContext = createContext<{
  getUserRoles: (bearerToken: string) => void;
  IS_ADMIN: boolean;
  IS_USER: boolean;
  USER_ID: string;
} | null>(null);

export default function AuthProvider({ children }: React.PropsWithChildren) {
  const [USER_ID, SET_USER_ID] = useState<string>('');
  const [IS_USER, SET_IS_USER] = useState<boolean>(false);
  const [IS_ADMIN, SET_IS_ADMIN] = useState<boolean>(false);

  /**
   * Get the current user's roles via Microsoft Graph API.
   * @param bearerToken
   */
  const getUserRoles = (bearerToken: string): void => {
    axios
      .get<MicrosoftGraphMeResponse>('https://graph.microsoft.com/v1.0/me', {
        headers: { Authorization: `Bearer ${bearerToken}` }
      })
      .then(({ data }) => {
        SET_USER_ID(data.id);
      })
      .catch(() => {
        SET_USER_ID('');
      });

    axios
      .get<{
        value: MicrosoftGraphMemberOfResponse[];
      }>('https://graph.microsoft.com/v1.0/me/memberOf', {
        headers: { Authorization: `Bearer ${bearerToken}` }
      })
      .then(({ data: { value } }) => {
        SET_IS_USER(value.some(group => group.displayName === USER_GROUP_NAME));
        SET_IS_ADMIN(value.some(group => group.displayName === ADMIN_GROUP_NAME));
      })
      .catch(() => {
        SET_IS_USER(false);
        SET_IS_ADMIN(false);
      });
  };

  return (
    <MsalProvider instance={publicClientApplication}>
      <AuthContext.Provider
        value={{
          getUserRoles,
          IS_ADMIN,
          IS_USER,
          USER_ID
        }}
      >
        {children}
      </AuthContext.Provider>
    </MsalProvider>
  );
}

export const useAuth = () => useContext(AuthContext)!;
