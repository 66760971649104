import { api } from 'app/api';
import { ApiResponse, JobClosureDto, JobClosuresParameters } from 'dtos';
import { URIQuery } from 'utils';

export const createJobClosure = (values: JobClosureDto) =>
  api.post<ApiResponse<JobClosureDto>>('job-closures', values).then(res => res.data);

export const getJobClosures = (parameters: JobClosuresParameters) =>
  api
    .get<ApiResponse<JobClosureDto[]>>('job-closures' + URIQuery({ ...parameters }))
    .then(res => res.data);

export const getJobClosureById = (id: string) =>
  api.get<ApiResponse<JobClosureDto>>('job-closures/' + id).then(res => res.data);

export const exportJobClosures = (parameters: JobClosuresParameters) =>
  api.get('job-closures/export' + URIQuery({ ...parameters })).then(res => {
    const blob = new Blob([res.data], { type: res.headers['content-type'] });
    const headers = res.headers['content-disposition'].split(';') as string[];

    const filename = headers
      .map(header => header.trim())
      .filter((header: string) => header.startsWith('filename='))[0]
      .replace('filename=', '');

    const a = document.createElement('a');
    const blobUrl = window.URL.createObjectURL(blob);
    a.href = blobUrl;
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
  });
