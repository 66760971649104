import { formatDateTime } from './formatDateTime';

export function getJobClosureReplyStatusById(
  jobClosureReplyStatusId: number,
  jobClosureCreatedDateTimeUtc?: string
): string {
  if (jobClosureReplyStatusId === 2) {
    return 'Approved ' + formatDateTime(jobClosureCreatedDateTimeUtc!);
  } else if (jobClosureReplyStatusId === 3) {
    return 'Rejected ' + formatDateTime(jobClosureCreatedDateTimeUtc!);
  }

  return 'Awaiting approval from Douglas Lindsay';
}
