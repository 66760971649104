import * as yup from 'yup';

export class JobClosureDto {
  id?: number = undefined;
  jobNumber?: string = undefined;
  committedCostsRemaining?: boolean = undefined;
  openReceivables?: boolean = undefined;
  openAccountsPayableInvoices?: boolean = undefined;
  areTherePendings?: boolean = undefined;
  isThisJobFullyBilledAndReceived?: boolean = undefined;
  createdDateTimeUtc?: string = undefined;
  submittedBy?: string = undefined;
  latestJobClosureReplyStatusId?: number = undefined;
  latestJobClosureReplyCreatedDateTimeUtc?: string = undefined;
}

export const jobClosureValidationSchema = yup.object({
  jobNumber: yup
    .string()
    .matches(/^\d+$/, 'Job # cannot contain non-numeric characters.')
    .typeError('Job # must be 6 whole digits.')
    .min(6, ' Job # cannot be less than 6 digits.')
    .max(6, 'Job # cannot be more than 6 digits.')
    .required('Job # is required.'),
  committedCostsRemaining: yup.boolean().required('Please select Yes or No.'),
  openReceivables: yup.boolean().required('Please select Yes or No.'),
  openAccountsPayableInvoices: yup.boolean().required('Please select Yes or No.'),
  areTherePendings: yup.boolean().required('Please select Yes or No.'),
  isThisJobFullyBilledAndReceived: yup.boolean().required('Please select Yes or No.')
});

export interface JobClosuresParameters {
  page: number;
  pageSize: number;
  jobNumber?: string;
  submittedFrom?: string;
  submittedTo?: string;
  submittedBy?: string;
  jobClosureReplyStatusId?: number;
}
