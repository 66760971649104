import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { AuthProvider } from 'context';
import { SnackbarProvider } from 'notistack';
import { Form, Submitted } from 'pages';
import * as reactRouterDom from 'react-router-dom';
import AppLayout from './Layout';
import theme from './theme';

const router = reactRouterDom.createBrowserRouter(
  reactRouterDom.createRoutesFromElements(
    <>
      <reactRouterDom.Route
        path='*'
        element={
          <AppLayout>
            <reactRouterDom.Navigate to='/form' />
          </AppLayout>
        }
      />

      <reactRouterDom.Route
        path='submitted'
        element={
          <AppLayout>
            <Submitted />
          </AppLayout>
        }
      />

      <reactRouterDom.Route
        path='form'
        element={
          <AppLayout>
            <Form />
          </AppLayout>
        }
      />
    </>
  )
);

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme} />
      <SnackbarProvider
        maxSnack={6}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <AuthProvider>
          <CssBaseline />
          <reactRouterDom.RouterProvider router={router} />
        </AuthProvider>
      </SnackbarProvider>
    </StyledEngineProvider>
  );
};

export default App;
