export function formatDateTime(date: Date | string): string {
  let str = date.toString();

  if (!(str.slice(-1) === 'Z')) {
    str = str + 'Z';
  }

  return new Date(str)
    .toLocaleString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })
    .replace(',', '');
}
